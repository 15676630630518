<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      statData: [
        {
          type: "line",
          title: "Vendas Realizadas",
          value: "R$ 0,00",
          icon: "bx-dollar-circle",
        },
        {
          type: "line",
          title: "Pedidos",
          value: "0",
          icon: "bx-shopping-bag",
        },
        {
          type: "line",
          title: "Ticket Médio",
          value: "R4 0,00",
          icon: "bx-cart",
        },
        {
          type: "line",
          title: "Taxa de Conversão",
          value: "0%",
          icon: "bx-line-chart",
        },
        {
          type: "line",
          title: "Pagamento Pendente",
          value: "0",
          icon: "bxs-dollar-circle",
        },
        {
          type: "line",
          title: "Nota Fiscal Pendente",
          value: "0",
          icon: "bx-receipt",
        },
        {
          type: "line",
          title: "Envio Pendente",
          value: "0",
          icon: "bx-cube",
        },
        {
          type: "line",
          title: "Entrega em Andamento",
          value: "0",
          icon: "bxs-truck",
        },
      ]
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <h6 class="m-0">Seja bem-vindo à Área do Parceiro</h6>
        <h6 class="m-0">{{ account.user.name }}</h6>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <h5 class="mt-2 mb-4">Últimos pedidos</h5>

    <div class="card">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        Nenhum pedido encontrato.
      </div>
    </div>
  </Layout>
</template>